import { Match, Show, Switch, createEffect, onMount, untrack, splitProps, createMemo, batch } from "solid-js";
import { fieldIsRequired } from "../SHARED/helpers";
import { Spinner } from "../../animations";
import { solidstate } from ":shared/utils/state-manager";
//
import type { MainProps, ButtonStatus } from "./_model";
import type { IFormControl } from "../SHARED/_model";

export default function (props: MainProps) {
  const [local, others] = splitProps(props, [
    "controls",
    "children",
    "statusStart",
    "statusInvalid",
    "statusValid",
    "statusSubmit",
    "onlyStatus",
    "classes",
    "triggers",
    "disabled",
    "events",
  ]);
  const controls_array: IFormControl[] = [];

  if (Array.isArray(local.controls)) {
    local.controls.forEach((control) => {
      if ("control" in control) {
        controls_array.push(control.control);
      } else {
        controls_array.push(control);
      }
    });
  } else {
    Object.values(local.controls).forEach((control) => {
      if ("control" in control) {
        controls_array.push(control.control);
      } else {
        controls_array.push(control);
      }
    });
  }
  const statuses = local.onlyStatus ?? ["valid", "invalid", "submit", "start"];
  const $status = solidstate.create<{ prev: ButtonStatus; current: ButtonStatus }>({ prev: "start", current: "start" });
  const $enable_button = solidstate.create(false);
  const $touched_control = createMemo(() => {
    const touched = controls_array.find((c) => c.isTouched);
    // console.log("touched :: ", touched?.id);
    return touched;
  });

  // createEffect(() => {
  //   // console.log("button state :: ", $enable_button.value);
  // });
  // set button enabled
  createEffect(() => {
    const prev_status = $status.value.prev;
    const status = $status.value.current;
    const disabled = local.disabled;
    if ("disabled" in local) {
      $enable_button.set(!disabled);
      return;
    }
    if (prev_status === status) {
      return;
    }
    if (statuses.indexOf(status) < 0) {
      $enable_button.set(local.events?.onSetButtonEnabledState?.(true, status) ?? true);
    } else {
      if (status !== "valid") {
        $enable_button.set(local.events?.onSetButtonEnabledState?.(false, status) ?? false);
      } else {
        $enable_button.set(local.events?.onSetButtonEnabledState?.(true, status) ?? true);
      }
    }
  });
  // listen to status change for inputs
  createEffect(() => {
    // we want this to update once
    batch(() => {
      let prev_status = $status.unwrap.prev;
      let status = $status.unwrap.current;
      // let manual_status = local.triggers?.manualStatus;
      let invalid_input = false;
      // console.log("called button effect :: ", status);
      if (local.triggers?.manualStatus) {
        status = local.triggers?.manualStatus;
        // console.log("setting manual status :: ", status);
        // $status.set(manual_status);
      } else {
        controls_array.forEach((c) => {
          const disabled = c.isDisabled;
          const valid = c.isValid;
          const required = c.isRequired && fieldIsRequired(c).checkValue();
          const invalid = disabled ? false : !valid || required;
          // console.log(
          //   "c is ",
          //   c.id,
          //   " :: invalid :: ",
          //   invalid,
          //   " :: valid :: ",
          //   valid,
          //   " :: required :: ",
          //   c.isRequired
          // );
          if (invalid && !invalid_input) {
            invalid_input = true;
          }
        });
        if (status !== "submit") {
          if (!invalid_input) {
            if (status !== "valid") {
              status = "valid";
            }
          } else if (status !== "invalid") {
            status = "invalid";
          }
        }
      }
      if (status !== $status.unwrap.current) {
        $status.set((s) => ({ prev: s.current, current: status }));
      }
    });
  });

  async function onClick(e: any) {
    // console.log("form button :: ");
    e.preventDefault();
    const prev_status = $status.unwrap.current;
    const notify_submit = statuses.indexOf("submit") > -1;
    if (notify_submit) {
      $status.set((s) => ({ prev: s.current, current: "submit" }));
    }
    try {
      if (typeof props.onclick === "function") {
        await Promise.resolve(props.onclick(e));
      } else if (typeof props.onClick === "function") {
        await Promise.resolve(props.onClick(e));
      } else if (typeof props.onSubmit === "function") {
        await Promise.resolve(props.onSubmit(e));
      } else if (typeof props.onsubmit === "function") {
        await Promise.resolve(props.onsubmit(e));
      }
    } catch (e) {
      // console.error(e);
    }
    if (notify_submit) {
      $status.set((s) => ({ prev: s.current, current: prev_status }));
    }
  }
  function Message() {
    const valid = createMemo(() =>
      typeof local.statusValid === "function" ? local.statusValid($touched_control()) : local.statusValid ?? "Submit"
    );
    const invalid = createMemo(() =>
      typeof local.statusInvalid === "function"
        ? local.statusInvalid($touched_control())
        : local.statusInvalid ?? "Complete Form"
    );
    const submit = createMemo(() =>
      typeof local.statusSubmit === "function"
        ? local.statusSubmit($touched_control())
        : local.statusSubmit ?? <Spinner class={`flex w-25px h-25px text-0.8rem ${local.classes?.spinner ?? ""}`} />
    );
    const start = createMemo(() =>
      typeof local.statusStart === "function"
        ? local.statusStart($touched_control())
        : local.statusStart ?? "Enter Fields"
    );
    return (
      <Show when={statuses.indexOf($status.value.current) > -1} fallback={valid()}>
        <Switch fallback={valid()}>
          <Match when={$status.value.current === "submit"}>{submit()}</Match>
          <Match when={$status.value.current === "start"}>{start()}</Match>
          <Match when={$status.value.current === "invalid"}>{invalid()}</Match>
        </Switch>
      </Show>
    );
  }
  onMount(() => {});
  return (
    <button {...props} onclick={onClick} onClick={undefined} disabled={!$enable_button.value}>
      {local.children?.($status.value.current) ?? <Message />}
    </button>
  );
}
